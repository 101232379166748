import SvgInset from '@/components/SvgInset';
import { CDN_URL } from '@/configs';
import { ROUTE_PATH } from '@/constants/route-path';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import cs from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import s from './styles.module.scss';

interface IProps {
  theme?: string;
  show: boolean;
  handleClose: () => void;
}

const MobileMenu: React.FC<IProps> = ({
  theme,
  show,
  handleClose,
}): React.ReactElement => {
  const router = useRouter();

  return (
    <Drawer placement={'right'} onClose={handleClose} isOpen={show} size={'md'}>
      <DrawerOverlay />
      <DrawerContent className={cs(s.content)}>
        <DrawerHeader className={s.menuHeader}>
          <button className={s.closeBtn} onClick={handleClose}>
            <SvgInset svgUrl={`${CDN_URL}/icons/close-menu-ic-24.svg`} />
          </button>
        </DrawerHeader>
        <DrawerBody className={s.wrapper}>
          <div className={cs(s.menuBody, 'disable-scrollbar')}>
            <ul className={s.menuList}>
              <li className={s.menuItem}>
                <a className={s.menuLink} href={ROUTE_PATH.SOCIAL}>
                  SocialFi
                </a>
              </li>
              <li className={s.menuItem}>
                <a className={s.menuLink} href={ROUTE_PATH.GAMEFI}>
                  GameFi
                </a>
              </li>
              <li className={s.menuItem}>
                <a className={s.menuLink} href={ROUTE_PATH.DEFI}>
                  DeFi
                </a>
              </li>
              <li className={s.menuItem}>
                <a className={s.menuLink} href={ROUTE_PATH.NFTS}>
                  NFTs
                </a>
              </li>
              <li className={s.menuItem}>
                <Accordion className={s.accordion} defaultActiveKey="">
                  <Accordion.Item className={s.accordionItem} eventKey="0">
                    <Accordion.Header className={s.accordionHeader}>
                      GM & Souls
                    </Accordion.Header>
                    <Accordion.Body className={s.accordionBody}>
                      <ul className={s.soulMenu}>
                        <li className={s.soulMenuItem}>
                          <a className={s.menuLink} href={ROUTE_PATH.GM_SOULS}>
                            Souls
                          </a>
                        </li>
                        <li className={s.soulMenuItem}>
                          <a className={s.menuLink} href={ROUTE_PATH.GM}>
                            GM
                          </a>
                        </li>
                        <li className={s.soulMenuItem}>
                          <a className={s.menuLink} href={ROUTE_PATH.ORPHANAGE}>
                            Orphanage
                          </a>
                        </li>
                        <li className={s.soulMenuItem}>
                          <a className={s.menuLink} href={ROUTE_PATH.TECH}>
                            Tech
                          </a>
                        </li>
                        <li className={s.soulMenuItem}>
                          <a className={s.menuLink} href={ROUTE_PATH.ART}>
                            Art
                          </a>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </li>
              <li className={s.menuItem}>
                <a
                  className={cs(s.menuLink, s.gradientText)}
                  href={ROUTE_PATH.TRUSTLESS_COMPUTER}
                >
                  Build
                </a>
              </li>
              <li className={s.menuItem}>
                <a className={s.menuLink} href={ROUTE_PATH.STORY}>
                  Our Story
                </a>
              </li>
            </ul>
          </div>
        </DrawerBody>
        <DrawerFooter pb={10} alignItems={'center'} gap={2}>
          <div className={s.socialWrapper}>
            <a href={'https://discord.gg/yNbatuGMDG'} className={s.socialLink}>
              <SvgInset
                size={20}
                svgUrl={`https://cdn.generative.xyz/soul-art/ic-discord.svg`}
                className="fill-black"
              />
            </a>
            <a
              href={'https://twitter.com/NewBitcoinCity'}
              className={s.socialLink}
            >
              <SvgInset
                size={20}
                svgUrl={`https://cdn.generative.xyz/soul-art/ic_twitter.svg`}
                className={cs('fill-black', s.menuItem_icon)}
              />
            </a>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default React.memo(MobileMenu);
