import SvgInset from '@/components/SvgInset';
import { CDN_URL_ICONS } from '@/configs';
import { AssetsContext } from '@/contexts/assets-context';
import { WalletContext } from '@/contexts/wallet-context';
import useWindowSize from '@/hooks/useWindowSize';
import { formatEVMAddress } from '@/utils/format';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { formatEthPriceInput } from '@trustless-computer/dapp-core';
import { useWeb3React } from '@web3-react/core';
import copy from 'copy-to-clipboard';
import React, { useContext } from 'react';
import { toast } from 'react-hot-toast';
import s from './styles.module.scss';

const NOSWallet = React.memo(() => {
  const { handleShowGetTcModal } = useContext(WalletContext);
  const { juiceBalance } = useContext(AssetsContext);
  const { account } = useWeb3React();
  const { mobileScreen } = useWindowSize();

  const onClickCopy = (content: string) => {
    copy(content);
    toast.success('Copied');
  };

  const handleGetTc = () => {
    if (account) {
      handleShowGetTcModal(undefined, account, 'Buy TC');
    }
  };

  const renderChipInfoPopover = () => {
    return (
      <Popover trigger={mobileScreen ? 'click' : 'hover'} isLazy>
        <PopoverTrigger>
          <div style={{ cursor: 'pointer' }}>
            <SvgInset size={16} svgUrl={`${CDN_URL_ICONS}/info-circle.svg`} />
          </div>
        </PopoverTrigger>
        <PopoverContent className={s.tooltipWrap}>
          <PopoverBody>
            <div>
              <p className={s.tooltipTitle}>NBC Wallet</p>
              <p className={s.tooltipContent}>
                A crypto wallet used to manage tokens and execute transactions
                on NOS.
              </p>
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <div className={s.container}>
      <div className={s.content}>
        <div className={s.wTitle}>
          <p className={s.title}>NBC WALLET</p>
          {renderChipInfoPopover()}
        </div>
        <div className={s.address} onClick={() => onClickCopy(account || '')}>
          <p>{formatEVMAddress(account || '...')}</p>
          <img className={s.icon} src={`${CDN_URL_ICONS}/ion_copy.svg`} />
        </div>
      </div>
      <div className={s.wBalance}>
        <p className={s.balance}>
          {formatEthPriceInput(juiceBalance.toString())} TC
        </p>
        <div className={s.getTc} onClick={handleGetTc}>
          <img className={s.icon} src={`${CDN_URL_ICONS}/tc-white.svg`} />
          <p>Get TC</p>
        </div>
      </div>
    </div>
  );
});

export default NOSWallet;
