import SvgInset from '@/components/SvgInset';
import { CDN_URL, CDN_URL_ICONS } from '@/configs';
import { Flex } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import s from './styles.module.scss';
import cs from 'classnames';
import { FOOTER_MENU_RIGHT } from '@/constants/header';
import { useRouter } from 'next/router';

const Footer = ({ isLight }: { isLight?: boolean }) => {
  const router = useRouter();
  const activePath = router.pathname.split('/')[1];

  return (
    <div className={cs(s.container, { [`${s.container__light}`]: isLight })}>
      <footer className={cs(s.footer, { [`${s.footer__light}`]: isLight })}>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div className={s.wrapBrand}>
            <p className={s.brand}>New Bitcoin City</p>
            <p className={s.year}>© 2023</p>
          </div>
          <div className={s.socialShare}>
            {FOOTER_MENU_RIGHT.map(menu => {
              return (
                <a
                  className={cs(s.navLink, {
                    [`${s.navLink__active}`]: activePath === menu.activePath,
                  })}
                  href={menu.route}
                >
                  {menu.name}
                </a>
              );
            })}
            <Link
              className={s.link}
              href={'https://discord.gg/yNbatuGMDG'}
              target="_blank"
            >
              <SvgInset
                size={28}
                svgUrl={`${CDN_URL_ICONS}/mingcute_discord-fill.svg`}
              />
            </Link>
            <Link
              className={s.link}
              href={'https://twitter.com/NewBitcoinCity'}
              target="_blank"
            >
              <SvgInset size={28} svgUrl={`${CDN_URL_ICONS}/mingcute_tw.svg`} />
            </Link>
          </div>
        </Flex>
      </footer>
    </div>
  );
};

export default Footer;
