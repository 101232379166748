import { CDN_URL_ICONS } from '@/configs';
import { AssetsContext } from '@/contexts/assets-context';
import { Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { formatEthPriceInput } from '@trustless-computer/dapp-core';
import { useWeb3React } from '@web3-react/core';
import { default as classNames, default as cs } from 'classnames';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import {
  ACTIVE_PATH_WALLET_DARK,
  ACTIVE_SUB_PATH_WALLET_DARK,
} from '@/constants/header';
import NOSWallet from './NOSWallet';
import s from './styles.module.scss';
import { SupportedChainId } from '@/constants/chains';
import { WalletContext } from '@/contexts/wallet-context';
import useContentWindowMessage from '@/hooks/useContentWindowMessage';
import { switchChain } from '@/utils';
import sleep from '@/utils/sleep';

const WalletConnectV3 = React.memo(
  ({
    isLight,
    isOpen,
    onClose,
    onOpen,
  }: {
    isLight?: boolean;
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
  }) => {
    const router = useRouter();

    const { isActive, account, chainId, connector } = useWeb3React();
    const { juiceBalance } = useContext(AssetsContext);
    const { onConnect } = useContext(WalletContext);
    const { onPostMessageConnectWithSignatureTrustless } =
      useContentWindowMessage();

    const isNeedSwitchNetworkL2 =
      chainId !== SupportedChainId.TRUSTLESS_COMPUTER_LAYER_2;
    const isNeedConnectL2 = !isActive || !account;

    const activePath = router.pathname.split('/')[1];
    const activePath2 = router.pathname.split('/')[2];

    const isInGameRoute =
      ACTIVE_PATH_WALLET_DARK.includes(activePath) ||
      ACTIVE_SUB_PATH_WALLET_DARK.includes(activePath2);

    const balanceNOS = Number(juiceBalance) || 0;

    const handleConnect = async () => {
      const isTrustlessComputer = window.location.href.includes('tc');

      if (isTrustlessComputer) {
        onConnect(SupportedChainId.TRUSTLESS_COMPUTER_LAYER_2);
        if (isNeedSwitchNetworkL2) {
          switchChain(SupportedChainId.TRUSTLESS_COMPUTER_LAYER_2);
        }
        onPostMessageConnectWithSignatureTrustless();
        let count = 0;
        while (count <= 3) {
          if (connector.connectEagerly) {
            await connector.connectEagerly();
          } else {
            await connector.activate();
          }
          await sleep(5);
          count += 1;
        }
        return;
      } else {
        if (isNeedSwitchNetworkL2) {
          switchChain(SupportedChainId.TRUSTLESS_COMPUTER_LAYER_2);
        } else {
          onConnect(SupportedChainId.TRUSTLESS_COMPUTER_LAYER_2);
        }
      }
    };

    if (isNeedConnectL2) {
      return (
        <button
          onClick={handleConnect}
          className={classNames(
            s.btnConnect,
            isInGameRoute || !isLight ? s.btnConnect_light : s.btnConnect_dark
          )}
        >
          <p>Connect Wallet</p>
        </button>
      );
    }

    return (
      <div className={cs(s.container)}>
        <Menu
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          placement="bottom-end"
        >
          <MenuButton className={s.container_menuWrapper}>
            <div className={s.accountContainer}>
              <div
                className={cs(s.balance, { [`${s.balance_dark}`]: isLight })}
              >
                <img
                  className={s.icon}
                  src={`${CDN_URL_ICONS}/${
                    isLight ? 'bxs_coin_black' : 'bxs_coin'
                  }.svg`}
                />
                <p>{formatEthPriceInput(balanceNOS.toString())} TC</p>
              </div>
            </div>
          </MenuButton>
          <MenuList className={s.menuList}>
            <div className={s.wallets}>
              <NOSWallet />
            </div>
          </MenuList>
        </Menu>
      </div>
    );
  }
);

export default WalletConnectV3;
