import { ROUTE_PATH } from '@/constants/route-path';
import { isProduction } from '@/utils/commons';

export const TOP_MENU_LEFT = [
  {
    name: 'GameFi',
    route: ROUTE_PATH.GAMEFI,
    activePath: 'mega-whales',
  },
  {
    name: 'NFTs',
    route: ROUTE_PATH.NFTS,
    activePath: 'nfts',
  },
  {
    name: 'AI',
    route: ROUTE_PATH.AI,
    activePath: 'ai',
  },
  {
    name: 'GM & Souls',
    route: ROUTE_PATH.GM_SOULS,
    activePath: 'souls',
  },
  {
    name: 'Trustless Computer',
    route: ROUTE_PATH.TRUSTLESS_COMPUTER,
    activePath: 'tc',
    isExternal: true,
  },
  {
    name: 'Our Story',
    route: ROUTE_PATH.STORY,
    activePath: 'story',
  },
];

const APP_URL = isProduction()
  ? 'https://alpha.wtf'
  : 'https://dev.alpha.wtf';

export const TOP_CENTER_LEFT = [
  {
    name: 'Install',
    route: '/',
    activePath: '',
  },
  {
    name: 'Build',
    route: ROUTE_PATH.TRUSTLESS_COMPUTER,
    activePath: 'tc',
    isExternal: true,
  },
];

export const TOP_MENU_RIGHT = [];

export const FOOTER_MENU_RIGHT = [
  {
    name: 'Blog',
    route: ROUTE_PATH.BLOG,
    activePath: 'blog',
  },
];

export const ACTIVE_PATH_WALLET_DARK = [
  '',
  'arcade',
  'developers',
  'gamefi',
  'topup',
  'ai',
  'story',
];

export const ACTIVE_SUB_PATH_WALLET_DARK = ['layer1', 'token'];

export const MENU_MOBILE = [...TOP_MENU_LEFT, ...TOP_MENU_RIGHT];
